// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '../../../apps/axios'


// export const getData = createAsyncThunk('appInvoice/getData', async params => {
//    console.log(params)
//   const response = await axios.get('/apps/invoice/invoices', params)
//     console.log(response)
//   return {
//     params,
//     data: response.data.invoices,
//     allData: response.data.allData,
//     totalPages: response.data.total
//   }
// })

export const getData = createAsyncThunk('appUserWallet/get', async (params) => {
    console.log(params)
      
    const response = await axios
    .get(`http://localhost:5005/api/userWallet/get?page=${params.page}&limit=${params.perPage}&search${params.q ? `=${params.q}` : ''}`)
    console.log(response)
    return {
      params,
      data: response,
      allData: response,
      totalPages: response.data.data.totalPages 
    }
})

export const deleteUserWallet = createAsyncThunk('appPromocode/delete', async (id, { dispatch, getState }) => {
  console.log(id)
  axios.delete(`http://localhost:5005/api/userWallet/delete`, {
    params: { id }
  })
  .then(res => {
    console.log(res)
    if (res.data.success) {
    //   console.log(getState())
      alert(`User Wallet deleted successfully.`)
      dispatch(getData(getState().promocodes.params))
    } else {
      alert(`User Wallet couldn't be deleted.`)
    }
  })
  .catch(err => {
    console.log(err)
  })
 
  return id
})

export const appUserWallet = createSlice({
  name: 'appUserWallet',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
    //   console.log(action)
      state.data = action.payload.allData.data.data.alldata
      state.allData = action.payload.data.data.data.alldata
      state.total = action.payload.totalPages
      state.params = action.payload.params
    })
  }
})

export default appUserWallet.reducer
