// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "../../../apps/axios";

// export const getData = createAsyncThunk('appInvoice/getData', async params => {
//    console.log(params)
//   const response = await axios.get('/apps/invoice/invoices', params)
//     console.log(response)
//   return {
//     params,
//     data: response.data.invoices,
//     allData: response.data.allData,
//     totalPages: response.data.total
//   }
// })

export const getData = createAsyncThunk("appProposal/get", async (params) => {
  console.log(params);

  const response = await axios.get(`/api/proposal/get`, { params });
  console.log(response);
  return {
    params,
    data: response,
    allData: response,
    totalPages: response.data.data.totalPages,
    params,
    totalItems: response.data.data.totalItems,
    currentPage: response.data.data.currentPage,
  };
});

export const deleteProposal = createAsyncThunk(
  "appProposal/deleteProposal",
  async (id, { dispatch, getState }) => {
    console.log(id);
    axios
      .delete(`/api/category/admin/delete?id=${id}`)
      .then((res) => {
        console.log(res);
        alert(res.data.message);
        if (res.data.success) {
          dispatch(getData(getState().category.params));
        }
      })
      .catch((err) => {
        console.log(err);
      });

    return id;
  }
);

export const appProposalSlice = createSlice({
  name: "appProposal",
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data.data.data.alldata;
      state.allData = action.payload.data.data.data.alldata;
      state.total = action.payload.totalPages;
      state.params = action.payload.params;
      state.totalItems = action.payload.totalItems;
      state.currentPage = action.payload.currentPage;
    });
  },
});

export default appProposalSlice.reducer;
