// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '../../../apps/axios'


// export const getData = createAsyncThunk('appInvoice/getData', async params => {
//    console.log(params)
//   const response = await axios.get('/apps/invoice/invoices', params)
//     console.log(response)
//   return {
//     params,
//     data: response.data.invoices,
//     allData: response.data.allData,
//     totalPages: response.data.total
//   }
// })

export const getData = createAsyncThunk('appUserRequest/get', async (params) => {
    console.log(params)
      
    const response = await axios
    .get(`/api/user_requests/get`)
    console.log(response)
    return {
      params,
      data: response.data.data.alldata,
      allData: response.data.data.alldata,
      totalPages: response.data.data.totalPages,
      totalItems: response.data.data.totalItems,
      currentPage: response.data.data.currentPage
    }
})

export const deleteUserRequest = createAsyncThunk('appUserRequest/delete', async (id, { dispatch, getState }) => {
  console.log(id)
  axios.delete(`/api/user_requests/delete`, {
    params: { id }
  })
  .then(res => {
    console.log(res)
    if (res.data.success) {
      console.log(getState())
      dispatch(getData(getState().userRequests.params))
      alert(`User request deleted successfully.`)
    }
  })
  .catch(err => {
    console.log(err)
  })
 
  return id
})

export const appUserRequestSlice = createSlice({
  name: 'appUserRequest',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
    //   console.log(action)
      state.data = action.payload.data
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
      state.currentPage = action.payload.currentPage
      state.totalItems = action.payload.totalItems
      state.params = action.payload.params
    })
  }
})

export default appUserRequestSlice.reducer
