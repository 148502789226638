import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../views/apps/axios";

const setSettings = createAsyncThunk("appSetting/set", async () => {
  const allSettings = await axios.get(`/api/setting`);
  if (allSettings.data.success) {
    return allSettings.data.data;
  } else {
    alert(`Couldn't fetch settings.`);
    return null;
  }
});

const initialUser = () => {
  if (localStorage.getItem("client") === null) {
    localStorage.setItem("client", null);
  }

  const item = window.localStorage.getItem("client");
  //** Parse stored json or if none return initialValue
  return item !== null ? JSON.parse(item) : {};
};

export const loginAsCustomer = createAsyncThunk(
  "appSetting/loginAsCustomer",
  async (config) => {
    const loginResponse = await axios.post(
      `/api/auth/admin/loginCustomer`,
      {
        email: config.email,
        mobile: config.mobile,
      },
      {
        headers: {
          Authorization: `Bearer ${config.token}`,
        },
      }
    );

    console.log(33, loginResponse);

    if (loginResponse.data.success) {
      const userData = {
        id: loginResponse.data.data.id,
        email: loginResponse.data.data.email,
        image: loginResponse.data.data.image,
        mobile: loginResponse.data.data.mobile,
        referral_code: loginResponse.data.data.referral_code,
        firstname: loginResponse.data.data.firstname,
        lastname: loginResponse.data.data.lastname,
        accessToken: loginResponse.data.data.token,
        refreshToken: loginResponse.data.data.token,
        uid: loginResponse.data.data.uid,
      };

      localStorage.setItem("client", JSON.stringify(userData));

      alert(`User logged in successfully.`);

      return userData;
    } else {
      alert(`Some error occured`);
    }
  }
);

export const appSettingsSlice = createSlice({
  name: "appSettings",
  initialState: {
    settings: {
      data: {},
      dataSet: false,
    },
    user: { data: initialUser() },
  },

  reducers: {
    login: (state, action) => {
      state.user.data = action.payload;
      localStorage.setItem("client", JSON.stringify(action.payload));
    },
    logout: (state, action) => {
      state.user.data = action.payload;
      localStorage.removeItem("client");
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setSettings.fulfilled, (state, action) => {
      if (action.payload) {
        state.settings.data = action.payload;
        state.settings.dataSet = true;
      }
    });
    builder.addCase(loginAsCustomer.fulfilled, (state, action) => {
      if (action.payload) {
        state.user.data = action.payload;
      }
    });
  },
});

export { setSettings };
export const { login, logout } = appSettingsSlice.actions;
export default appSettingsSlice.reducer;
