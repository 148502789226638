// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "../../../../../views/apps/axios";
import androidimg from "../../../../assets/webelimage/deliver/Vector.svg";
import appleimg from "../../../../assets/webelimage/deliver/apple.svg";
import pwqimg from "../../../../assets/webelimage/deliver/pwa.svg";
import webimg from "../../../../assets/webelimage/deliver/website.svg";
import toast from "react-hot-toast";
import reactimg from "../../../../assets/webelimage/deliver/react.svg";
import mysqlimg from "../../../../assets/webelimage/deliver/mysql.svg";
import flutterimg from "../../../../assets/webelimage/deliver/flutter.svg";
import nodeimg from "../../../../assets/webelimage/deliver/node.svg";
import nativeimg from "../../../../assets/webelimage/deliver/native.svg";
import { loadState, saveState } from "./localStorage";
import phpimg from "../../../../assets/webelimage/deliver/php.svg";
import serverimg from "../../../../assets/webelimage/deliver/servers.svg";
const notify = (msg) => toast(msg);
const setCategory = createAsyncThunk("appCategory/set", async () => {
  try {
    const allCategoriesResponse = await axios.get(`/api/category/getAll`);
    if (allCategoriesResponse.data.success) {
      const allCategoriesTemp = [];
      allCategoriesResponse.data.data.forEach((category) => {
        allCategoriesTemp.push({
          id: category.id,
          title: category.title,
          icon1: category.icon1,
          icon2: category.icon2,
          icon3: category.icon3,
          icon4: category.icon4,
          selected: false,
        });
      });

      return allCategoriesTemp;
    } else {
      notify("Some error occured fetching the categories.");
    }
  } catch (err) {
    notify("Some error occured fetching the categories.");
    return null;
  }
});

const setClient = createAsyncThunk("appClient/set", async (data) => {
  try {
    return data;
  } catch (err) {
    console.log(37, err);
    return null;
  }
});

// const setDeadline = createAsyncThunk('appDeadline/set', async (deadline) => {
//     return {
//         deadline
//     };
// })

const setBaseApp = createAsyncThunk(
  "appBaseApp/set",
  async (val, { dispatch, getState }) => {
    // const allBaseAppCategoriesResponse = await axios.get(`/api/baseAppCategory/getByCategoryId?category_id=${getState().data.category.selectedCategory.id}`);
    const allBaseAppCategoriesResponse = await axios.get(
      `/api/baseAppCategory/getAll`
    );

    if (allBaseAppCategoriesResponse.data.success) {
      const allBaseAppCategoriesTemp = [];
      await allBaseAppCategoriesResponse.data.data.forEach(
        (baseAppCategory) => {
          allBaseAppCategoriesTemp.push({
            id: baseAppCategory.id,
            title: baseAppCategory.title,
            img: baseAppCategory.img,
            category_id: baseAppCategory.category_id,
            list: Array.isArray(baseAppCategory.baseapps)
              ? baseAppCategory.baseapps.map((base) => {
                  return {
                    id: base.id,
                    name: base.name,
                    img: base.img,
                    price: base.price,
                    selected: false,
                  };
                })
              : null,
          });
        }
      );
      //  console.log(allBaseAppCategoriesTemp);
      return allBaseAppCategoriesTemp;
    } else {
      notify("Some error occured fetching the baseapp.");

      return null;
    }
  }
);

const setFeatureApp = createAsyncThunk(
  "appFeatureApp/set",
  async (val, { dispatch, getState }) => {
    // return allFeatures;

    // const allFeatureCategoriesResponse = await axios.get(`/api/featureCategory/getByBaseAppId?base_app_id=${getState().data.baseApp.selectedBaseApp.baseApp.id}`)
    const allFeatureCategoriesResponse = await axios.get(
      `/api/featureCategory/getAll`
    );
    // console.log(allFeatureCategoriesResponse);
    if (allFeatureCategoriesResponse.data.success) {
      //   console.log(allFeatureCategoriesResponse.data.features[2]);
      const allFeatureCategoriesTemp = [];
      await allFeatureCategoriesResponse.data.data.forEach(
        (featureCategory) => {
          allFeatureCategoriesTemp.push({
            id: featureCategory.id,
            title: featureCategory.title,
            img: featureCategory.img,
            base_app_id: featureCategory.base_app_id,
            list: allFeatureCategoriesResponse.data.features[
              featureCategory.id
            ].map((feature) => {
              return {
                id: feature.id,
                name: feature.name,
                img: feature.img,
                tags: feature.tags,
                is_trending: feature.is_trending,
                price: feature.price,
                selected: false,
              };
            }),
            // list: Array.isArray(featureCategory.features)
            //   ? featureCategory.features.map((feature) => {
            //       return {
            //         id: feature.id,
            //         name: feature.name,
            //         img: feature.img,
            //         tags: feature.tags,
            //         is_trending: feature.is_trending,
            //         price: feature.price,
            //         selected: false,
            //       };
            //     })
            //   : [],
          });
        }
      );
      // console.log(allFeatureCategoriesTemp);
      return allFeatureCategoriesTemp;
    } else {
      notify("Some error occured fetching the base Apps.");

      return null;
    }
  }
);

export const appDataSlice = createSlice({
  name: "appData",
  initialState: {
    category: {
      val: "",
      selectedCategory: loadState("category") || {},
      dataSet: false,
    },
    funding: {
      val: [
        {
          id: 1,
          title: "Self",
          selected: false,
        },
        {
          id: 2,
          title: "Investors",
          selected: false,
        },
        {
          id: 3,
          title: "No Funding",
          selected: false,
        },
        {
          id: 4,
          title: "Other",
          selected: false,
        },
      ],
      selectedFunding: loadState("funding") || {},
      dataSet: false,
    },
    budget: {
      val: [
        {
          id: 1,
          title: "$1 - $1k",
          selected: false,
        },
        {
          id: 2,
          title: "$1k - $10k",
          selected: false,
        },
        {
          id: 3,
          title: "$1 - $50k",
          selected: false,
        },
        {
          id: 4,
          title: "Above $50k",
          selected: false,
        },
      ],
      selectedBudget: loadState("budget") || {},
      dataSet: false,
    },
    deadline: {
      val: loadState("deadline") || "",
      dataSet: false,
    },
    client: {
      val: "",
    },
    baseApp: {
      val: null,
      selectedBaseApp: {
        baseAppCategory: "",
        baseApp: loadState("baseapp") || "",
      },
      selecetedBase: loadState("baseapp") || {},
      dataSet: false,
    },
    features: {
      val: [],
      dataSet: false,
      selectedfeatures: loadState("features") || [],
    },
    platform: {
      val: [
        {
          title: "Android",
          icon: androidimg,
          selected: false,
        },
        {
          title: "Apple",
          icon: appleimg,
          selected: false,
        },
        {
          title: "PWA",
          icon: pwqimg,
          selected: false,
        },
        {
          title: "Website",
          icon: webimg,
          selected: false,
        },
      ],
      selectedPlatform: loadState("platform") || {},
      dataSet: false,
    },
    frontend: {
      val: [
        {
          title: "React",
          icon: reactimg,
          selected: false,
        },
        {
          title: "Flutter",
          icon: flutterimg,
          selected: false,
        },
        {
          title: "Native",
          icon: nativeimg,
          selected: false,
        },
      ],
      selectedFrontend: loadState("frontend") || {},
      dataSet: false,
    },
    backend: {
      val: [
        {
          title: "NodeJs",
          icon: nodeimg,
          selected: false,
        },
        {
          title: "PHP",
          icon: phpimg,
          selected: false,
        },
      ],
      selectedBackend: loadState("backend") || {},
      dataSet: false,
    },
    database: {
      val: [
        {
          title: "MySQL",
          icon: mysqlimg,
          selected: false,
        },
        {
          title: "MongoDB",
          icon: appleimg,
          selected: false,
        },
      ],
      selectedDatabase: loadState("database") || {},
      dataSet: false,
    },
    amcplan: {
      val: [
        {
          title: "Care Lite",
          price: 15000,
          features: [
            { f: "Dedicated Project Manager" },
            { f: "Available anytime during office hours to resolve bugs" },
            { f: "24 x 7 Uptime Monitoring" },
          ],
          selected: false,
        },
        {
          title: "Care Pro",
          price: 80000,
          features: [
            { f: "Dedicated one Developer (1-2yr)" },
            { f: "Bugs resolving 24*7" },
            { f: "Extended Development (office hrs)" },
            { f: "24x7 Uptime monitoring" },
          ],
          selected: false,
        },
        {
          title: "Care Complete",
          price: 250000,
          features: [
            { f: "Dedicated Two Developer" },
            { f: "Dedicated tester" },
            { f: "Extended Development" },
            { f: "Bugs resolving 24*7" },
            { f: "24x7 Uptime monitoring" },
          ],
          selected: false,
        },
      ],
      selectedAmcplan: loadState("amcplan") || {},
      dataSet: false,
    },
    cloudplan: {
      val: [
        {
          title1: "Cloud",
          title2: "Pro",
          price: 20000,
          subtitle: "Webel Cloud take care of all traffic on app",
          features: [
            { f: "Uptime : We guarantee 99.7% uptime" },
            { f: "HardWare : 16Gb Ram, 200GB NVme, 6 Core" },
            { f: "Data centre : Gurgaon, IN" },
            { f: "Dedicated Server Admin" },
          ],
          selected: false,
        },
        {
          title1: "Cloud",
          title2: "Lite",
          price: 5000,
          subtitle: "Webel Cloud take care of all traffic on app",
          features: [
            { f: "Uptime : We guarantee 99.7% uptime" },
            { f: "HardWare : 4Gb Ram, 30GB NVme, 2 Core" },
            { f: "Data centre : Gurgaon, IN" },
            { f: "Dedicated Server Admin" },
          ],
          selected: false,
        },
      ],
      selectedCloudplan: loadState("cloudplan") || {},
      dataSet: false,
    },
  },
  reducers: {
    selectCategoryHandler(state, action) {
      let selectedCategory = null;
      state.category.val = state.category.val.map((category) => {
        if (category.id === action.payload.id) {
          selectedCategory = {
            ...category,
            selected: !category.selected,
          };
          return {
            ...category,
            selected: !category.selected,
          };
        } else
          return {
            ...category,
            selected: false,
          };
      });
      if (selectedCategory.selected) {
        state.category.selectedCategory = selectedCategory;
        saveState("category", selectedCategory);
      } else {
        state.category.selectedCategory = {};
        saveState("category", selectedCategory);
      }
    },

    selectFundingHandler(state, action) {
      if (action.payload === "Not sure") {
        state.funding.selectedFunding = {
          id: 5,
          title: "Not sure",
          selected: true,
        };

        state.funding.val = state.funding.val.map((funding) => {
          return {
            ...funding,
            selected: false,
          };
        });

        return;
      } else if (action.payload === "Clear selected") {
        if (state.funding.selectedFunding.title === "Not sure") {
          state.funding.selectedFunding = {};
          saveState("funding", selectedFunding);
        }
        return;
      }

      let selectedFunding = null;

      state.funding.val = state.funding.val.map((funding) => {
        if (funding.id === action.payload.id) {
          selectedFunding = {
            ...funding,
            selected: !funding.selected,
          };
          return {
            ...funding,
            selected: !funding.selected,
          };
        } else {
          return {
            ...funding,
            selected: false,
          };
        }
      });

      if (selectedFunding.selected) {
        state.funding.selectedFunding = selectedFunding;
        saveState("funding", selectedFunding);
      } else {
        state.funding.selectedFunding = {};
        saveState("funding", selectedFunding);
      }
    },

    selectBudgetHandler(state, action) {
      if (action.payload === "Not sure") {
        state.budget.selectedBudget = {
          id: 5,
          title: "Not sure",
          selected: true,
        };
        saveState("budget", {
          id: 5,
          title: "Not sure",
          selected: true,
        });
        state.budget.val = state.budget.val.map((budget) => {
          return {
            ...budget,
            selected: false,
          };
        });

        return;
      } else if (action.payload === "Clear selected") {
        if (state.budget.selectedBudget.title === "Not sure") {
          state.budget.selectedBudget = {};
          saveState("budget", {});
        }
        return;
      }

      let selectedBudget = null;

      state.budget.val = state.budget.val.map((budget) => {
        if (budget.id === action.payload.id) {
          selectedBudget = {
            ...budget,
            selected: !budget.selected,
          };
          return {
            ...budget,
            selected: !budget.selected,
          };
        } else {
          return {
            ...budget,
            selected: false,
          };
        }
      });

      if (selectedBudget.selected) {
        state.budget.selectedBudget = selectedBudget;
        saveState("budget", selectedBudget);
      } else {
        state.budget.selectedBudget = {};
        saveState("budget", {});
      }

      // state.budget.val = action.payload;
    },

    selectDeadlineHandler(state, action) {
      if (action.payload === "Clear selected") {
        state.deadline.val = "";
        saveState("deadline", "");
        return;
      }

      state.deadline.val = action.payload;
      saveState("deadline", action.payload);
      state.deadline.dataSet = true;
    },
    selectBaseAppHandler(state, action) {
      const { baseAppCategory, baseApp } = action.payload;
      saveState("baseapp", baseApp);
      state.baseApp.selectedBaseApp = {
        baseAppCategory,
        baseApp,
      };
      state.baseApp.val = state.baseApp.val.map((baseAppCategory) => {
        if (baseAppCategory.id === baseAppCategory.id) {
          return {
            ...baseAppCategory,
            list: baseAppCategory.list.map((baseAppInList) => {
              if (baseAppInList.id === baseApp.id) {
                return {
                  ...baseAppInList,
                  selected: !baseAppInList.selected,
                };
              } else return baseAppInList;
            }),
          };
        } else {
          //   console.log("setbaseapp done in store");
          return baseAppCategory;
        }
      });
    },

    selectFeatureHandler(state, action) {
      const { featureCategory, feature } = action.payload;
      state.features.val = state.features.val.map((category) => {
        // console.log(state.features.val);
        // if (category.id === featureCategory.id) {
        return {
          ...category,
          list: category.list.map((featureInList) => {
            if (featureInList.id === feature.id) {
              return {
                ...featureInList,
                selected: !featureInList.selected,
              };
            } else return featureInList;
          }),
        };

        // } else {
        //   return category;
        // }
      });

      const selectedFeatures = state.features.val.flatMap((category) => {
        return category.list.filter((featureInList) => featureInList.selected);
      });

      saveState(
        "features",
        selectedFeatures.filter((item, index, self) => {
          return index === self.findIndex((i) => i.id === item.id);
        })
      );
    },

    selectPlatformHandler(state, action) {
      let selectedPlatform = null;
      state.platform.val = state.platform.val.map((platform) => {
        if (platform.title === action.payload.title) {
          selectedPlatform = {
            ...platform,
            selected: !platform.selected,
          };
          return selectedPlatform;
        } else {
          return {
            ...platform,
            // selected: false,
          };
        }
      });

      const selectedPlatforms = state.platform.val.filter(
        (platform) => platform.selected
      );

      saveState("platform", selectedPlatforms);
    },

    selectBackendHandler(state, action) {
      let selectedBackend = null;
      state.backend.val = state.backend.val.map((backend) => {
        if (backend.title === action.payload.title) {
          selectedBackend = {
            ...backend,
            selected: !backend.selected,
          };
          return {
            ...backend,
            selected: !backend.selected,
          };
        } else
          return {
            ...backend,
            selected: false,
          };
      });
      if (selectedBackend.selected) {
        state.backend.selectedBackend = selectedBackend;
        saveState("backend", selectedBackend);
      } else {
        state.backend.selectedBackend = {};
        saveState("backend", {});
      }
    },

    selectFrontendHandler(state, action) {
      let selectedFrontend = null;
      state.frontend.val = state.frontend.val.map((frontend) => {
        if (frontend.title === action.payload.title) {
          selectedFrontend = {
            ...frontend,
            selected: !frontend.selected,
          };
          return {
            ...frontend,
            selected: !frontend.selected,
          };
        } else
          return {
            ...frontend,
            selected: false,
          };
      });
      if (selectedFrontend.selected) {
        state.frontend.selectedFrontend = selectedFrontend;
        saveState("frontend", selectedFrontend);
      } else {
        state.frontend.selectedFrontend = {};
        saveState("frontend", {});
      }
    },

    selectDatabaseHandler(state, action) {
      let selectedDatabase = null;
      state.database.val = state.database.val.map((database) => {
        if (database.title === action.payload.title) {
          selectedDatabase = {
            ...database,
            selected: !database.selected,
          };
          return {
            ...database,
            selected: !database.selected,
          };
        } else
          return {
            ...database,
            selected: false,
          };
      });
      if (selectedDatabase.selected) {
        state.database.selectedDatabase = selectedDatabase;
        saveState("database", selectedDatabase);
      } else {
        state.database.selectedDatabase = {};
        saveState("database", {});
      }
    },
    selectAmcplanHandler(state, action) {
      let selectedAmcplan = null;
      state.amcplan.val = state.amcplan.val.map((amcplan) => {
        if (amcplan.title === action.payload.title) {
          selectedAmcplan = {
            ...amcplan,
            selected: !amcplan.selected,
          };
          return {
            ...amcplan,
            selected: !amcplan.selected,
          };
        } else
          return {
            ...amcplan,
            selected: false,
          };
      });
      if (selectedAmcplan.selected) {
        state.amcplan.selectedAmcplan = selectedAmcplan;
        saveState("amcplan", selectedAmcplan);
      } else {
        state.amcplan.selectedAmcplan = {};
        saveState("amcplan", {});
      }
    },
    selectCloudplanHandler(state, action) {
      let selectedCloudplan = null;
      state.cloudplan.val = state.cloudplan.val.map((cloudplan) => {
        if (cloudplan.title2 === action.payload.title2) {
          selectedCloudplan = {
            ...cloudplan,
            selected: !cloudplan.selected,
          };
          return {
            ...cloudplan,
            selected: !cloudplan.selected,
          };
        } else
          return {
            ...cloudplan,
            selected: false,
          };
      });
      if (selectedCloudplan != null && selectedCloudplan.selected) {
        state.cloudplan.selectedCloudplan = selectedCloudplan;
        saveState("cloudplan", selectedCloudplan);
      } else {
        state.cloudplan.selectedCloudplan = {};
        saveState("cloudplan", {});
      }
    },
  },

  // frontend select

  extraReducers: (builder) => {
    builder.addCase(setCategory.fulfilled, (state, action) => {
      if (action.payload) {
        state.category.val = action.payload;
        state.category.dataSet = true;
      }
    });
    builder.addCase(setBaseApp.fulfilled, (state, action) => {
      if (action.payload) {
        state.baseApp.val = action.payload;
        state.baseApp.dataSet = true;
      }
    });
    builder.addCase(setFeatureApp.fulfilled, (state, action) => {
      if (action.payload) {
        state.features.val = action.payload;
        state.features.dataSet = true;
      }
    });
    builder.addCase(setClient.fulfilled, (state, action) => {
      if (action.payload) {
        state.client.val = "hello";
      }
    });
  },
});

export default appDataSlice.reducer;
export { setFeatureApp, setBaseApp, setCategory, setClient };
export const {
  selectFeatureHandler,
  selectBaseAppHandler,
  selectCategoryHandler,
  selectBudgetHandler,
  selectFundingHandler,
  selectDeadlineHandler,
  selectPlatformHandler,
  selectDatabaseHandler,
  selectFrontendHandler,
  selectBackendHandler,
  selectAmcplanHandler,
  selectCloudplanHandler,
} = appDataSlice.actions;
// export {selectFeatureHandler};
