// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "../../../apps/axios";

export const getAllData = createAsyncThunk("appUsers/getAllData", async () => {
  const response = await axios.get("/api/auth/getAll");
  console.log(response);
  return response.data.data;
});

export const getData = createAsyncThunk("appUsers/getData", async (params) => {
  // console.log(params)
  const response = await axios.get(`/api/auth/admin/get`, { params });
  console.log(response);
  return {
    params,
    data: response.data.data.alldata,
    totalPages: response.data.data.totalPages,
    currentPage: response.data.data.currentPage,
    totalItems: response.data.data.totalItems,
  };
});

export const getUser = createAsyncThunk("appUsers/getUser", async (id) => {
  const response = await axios.get("/api/users/user", { id });
  return {
    params,
    data: response.data.data,
    totalPages: response.data.totalPages,
  };
});

export const addUser = createAsyncThunk(
  "appUsers/addUser",
  async (user, { dispatch, getState }) => {
    await axios.post("/apps/users/add-user", user);
    await dispatch(getData(getState().users.params));
    // await dispatch(getAllData())
    return user;
  }
);

export const deleteUser = createAsyncThunk(
  "appUsers/deleteUser",
  async (id, { dispatch, getState }) => {
    await axios.delete(`/api/auth/delete/${id}`);
    await dispatch(getData(getState().users.params));
    await dispatch(getAllData());
    return id;
  }
);

export const appUsersSlice = createSlice({
  name: "appUsers",
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload.rows;
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.allData = action.payload.data;
        state.params = action.payload.params;
        state.totalPages = action.payload.totalPages;
        state.totalItems = action.payload.totalItems;
        state.currentPage = action.payload.currentPage;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload;
      });
  },
});

export default appUsersSlice.reducer;
