// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '../../../../apps/axios'

export const getData = createAsyncThunk('appWebBuilderLayoutPacks/get', async params => {
  console.log(params)
      
  const response = await axios
  .get(`/api/webbuilder/layoutpacks/get`, {params})
  console.log(response)
  return {
    params,
    data: response,
    allData: response,
    totalPages: response.data.data.totalPages,
    params,
    totalItems: response.data.data.totalItems,
    currentPage: response.data.data.currentPage
  }
})

export const deleteWebBuilderLayoutPacks = createAsyncThunk('appWebBuilderLayoutPacks/deleteLayoutPack', async (id, { dispatch, getState }) => {
  console.log(id)
  axios.delete(`/api/webbuilder/layoutpacks/deleteById?id=${id}`)
  .then(res => {
    console.log(res)
    alert(res.data.message)
    if (res.data.success) {
      dispatch(getData(getState().category.params))
    }
  })
  .catch(err => {
    console.log(err)
  })
 
  return id
})

export const appWebBuilderLayoutPacksSlice = createSlice({
  name: 'appWebBuilderLayoutPacks',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data.data.data.alldata
      state.allData = action.payload.data.data.data.alldata
      state.total = action.payload.totalPages
      state.params = action.payload.params
      state.totalItems = action.payload.totalItems
      state.currentPage = action.payload.currentPage
    })
  }
})

export default appWebBuilderLayoutPacksSlice.reducer
