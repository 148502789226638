// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '../../../apps/axios'


// export const getData = createAsyncThunk('appInvoice/getData', async params => {
//    console.log(params)
//   const response = await axios.get('/apps/invoice/invoices', params)
//     console.log(response)
//   return {
//     params,
//     data: response.data.invoices,
//     allData: response.data.allData,
//     totalPages: response.data.total
//   }
// })

export const getData = createAsyncThunk('appProduct/getData', async params => {
  console.log(params)
      
  const response = await axios
  .get(`/api/product_variant/admin/get`, {params})
  console.log(response)
  return {
    params,
    data: response.data.data.alldata,
    allData: response.data.data.alldata,
    typeOptions: response.data.data.typeOptions,
    totalItems: response.data.data.totalItems,
    totalPages: response.data.data.totalPages,
    currentPage: response.data.data.currentPage
  }
})

export const deleteProductVariant = createAsyncThunk('appProduct/deleteProductVariant', async (id, { dispatch, getState }) => {
  console.log(id)
  axios.delete(`/api/product_variant/delete?id=${id}`)
  .then(response => {
    console.log(response)
    if (response.data.success) {
      alert(`Product variant deleted successfully.`)
      dispatch(getData(getState().products.params))
    } else {
      alert(`Couldn't delete the product variant.`)
    }
  })
 
  return id
})

export const appProductVariantSlice = createSlice({
  name: 'appProductVariant',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      // console.log(state, action)
      // console.log(action)
      state.data = action.payload.data
      state.allData = action.payload.allData
      state.typeOptions = action.payload.typeOptions
      state.total = action.payload.totalPages
      state.params = action.payload.params
      state.currentPage = action.payload.currentPage
      state.totalItems = action.payload.totalItems
    })
  }
})

export default appProductVariantSlice.reducer
