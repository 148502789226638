// ** Reducers Imports
import navbar from "./navbar";
import layout from "./layout";
import auth from "./authentication";
import todo from "@src/views/apps/todo/store";
import chat from "@src/views/apps/chat/store";
import admin from "@src/views/superadmin/Admin/store";
import users from "@src/views/superadmin/user/store";
// import category from '@src/views/apps/Category/store'
import email from "@src/views/apps/email/store";
import category from "@src/views/superadmin/invoice/store";
import baseAppCategories from "@src/views/superadmin/baseAppCategory/store";
import baseApps from "@src/views/superadmin/BaseApp/store";
import proposal from "@src/views/superadmin/Proposal/store";
import featureCategories from "@src/views/superadmin/FeatureCategory/store";
import features from "@src/views/superadmin/Feature/store";
// import invoice from '@src/views/apps/invoice/store'
import banner from "@src/views/superadmin/Banner/store";
import brand from "@src/views/superadmin/Brand/store";
import service from "@src/views/superadmin/Service/store";
import patient from "@src/views/superadmin/Patient/store";
import products from "@src/views/superadmin/Products/store";
import doctors from "@src/views/superadmin/Doctor/store";
import drivers from "@src/views/superadmin/Driver/store";
import productVariants from "@src/views/superadmin/ProductVariant/store";
import userRequests from "@src/views/superadmin/UserRequest/store";
import promocodes from "@src/views/superadmin/Promocode/store";
import adminaddresses from "@src/views/superadmin/AdminAddress/store";
import userWallets from "@src/views/superadmin/UserWallet/store";
import stores from "@src/views/superadmin/Store/store";
import orders from "@src/views/superadmin/Order/store";
import paymentMethods from "@src/views/superadmin/PaymentMethods/store";
import rolesAndPermissions from "@src/views/superadmin/RolesAndPermissions/store";
import labItems from "@src/views/superadmin/LabItem/store";
import labProducts from "@src/views/superadmin/LabProduct/store";
import labTestOrders from "@src/views/superadmin/LabTestOrder/store";
import subscriptions from "@src/views/superadmin/Subscriptions/store";
import subscriptionPlans from "@src/views/superadmin/SubscribedPlans/store";
import permission from "@src/views/superadmin/Permission/store";
import orderTransactions from "@src/views/superadmin/OrderTransactions/store";
import chatbot from "@src/views/superadmin/Chatbot/store";
import areas from "@src/views/superadmin/Area/store";
import calendar from "@src/views/apps/calendar/store";
import ecommerce from "@src/views/apps/ecommerce/store";
import dataTables from "@src/views/tables/data-tables/store";
import permissions from "@src/views/apps/roles-permissions/store";
import earnings from "@src/views/dashboard/earnings/store";
import data from "../frontend/views/frontend/components/store";
import appStore from "../frontend/AppStore";

import appWebBuilderCategory from '../views/superadmin/WebBuilder/Categories/store'
import appWebBuilderLayoutPacks from '../views/superadmin/WebBuilder/LayoutPacks/store'
import appWebBuilderLayouts from '../views/superadmin/WebBuilder/Layouts/store'

const rootReducer = {
  auth,
  todo,
  chat,
  email,
  users,
  admin,
  category,
  baseAppCategories,
  baseApps,
  proposal,
  featureCategories,
  features,
  // category,
  navbar,
  layout,
  // invoice,
  banner,
  brand,
  service,
  patient,
  products,
  doctors,
  drivers,
  productVariants,
  userRequests,
  promocodes,
  adminaddresses,
  userWallets,
  stores,
  orders,
  subscriptions,
  subscriptionPlans,
  permission,
  orderTransactions,
  chatbot,
  areas,
  paymentMethods,
  rolesAndPermissions,
  labItems,
  labProducts,
  labTestOrders,
  calendar,
  ecommerce,
  dataTables,
  permissions,
  earnings,
  data,
  appStore,
  appWebBuilderCategory,
  appWebBuilderLayoutPacks,
  appWebBuilderLayouts
};

export default rootReducer;
