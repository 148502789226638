// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '../../../apps/axios'

export const getAllData = createAsyncThunk('appAdmins/getAllData', async (params) => {
  const response = await axios.get('/api/admin/get', {params})
  console.log(response)
  return response.data.data.alldata
})

export const getData = createAsyncThunk('appAdmins/getData', async params => {

  console.log(params.token)

  const response = await axios
  .get(`/api/admin/get`, {
    params,
    headers: {
      authorization: `Bearer ${params.token}` 
    }
  })
  console.log(response)
  return {
    params,
    data: response.data.data.alldata,
    totalPages: response.data.data.totalPages,
    totalItems: response.data.data.totalItems,
    currentPage: response.data.data.currentPage
  }
})

export const getAdmin = createAsyncThunk('appAdmins/getAdmin', async id => {
  const response = await axios.get('/api/users/user', { id })
  return response.data.user
})

export const addAdmin = createAsyncThunk('appAdmins/addAdmin', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const deleteAdmin = createAsyncThunk('appAdmins/deleteAdmin', async (id, { dispatch, getState }) => {
  await axios.delete(`api/admin/delete?id=${id}`)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

export const appAdminSlice = createSlice({
  name: 'appAdmins',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.totalPages = action.payload.totalPages
        state.currentPage = action.payload.currentPage
        state.totalItems = action.payload.totalItems
      })
      .addCase(getAdmin.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default appAdminSlice.reducer
