// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '../../../apps/axios'


// export const getData = createAsyncThunk('appInvoice/getData', async params => {
//    console.log(params)
//   const response = await axios.get('/apps/invoice/invoices', params)
//     console.log(response)
//   return {
//     params,
//     data: response.data.invoices,
//     allData: response.data.allData,
//     totalPages: response.data.total
//   }
// })

export const getData = createAsyncThunk('appRolesAndPermissions/getData', async params => {
    console.log(params)
      
     const response = await axios.get(`/api/roles/get`, {params})
     console.log(response)
     return {
       params,
       data: response.data.data.alldata,
       allData: response.data.data.alldata,
       totalPages : response.data.data.totalPages,
       totalItems: response.data.data.totalItems,
       currentPage: response.data.data.currentPage
     }
})

export const deleteRoleAndPermission = createAsyncThunk('appRolesAndPermissions/deleteInvoice', async (id, { dispatch, getState }) => {
  
 const res = await axios.delete(`/api/rolesAndPermissions/delete/${row.id}`)
  console.log(res)
  await dispatch(getData(getState().rolesAndPermissions.params))
  return id
})

export const appRoleAndPermissionSlice = createSlice({
  name: 'appRolesAndPermissions',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
      state.params = action.payload.params
    })
  }
})

export default appRoleAndPermissionSlice.reducer
