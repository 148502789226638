// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '../../../apps/axios'


// export const getData = createAsyncThunk('appInvoice/getData', async params => {
//    console.log(params)
//   const response = await axios.get('/apps/invoice/invoices', params)
//     console.log(response)
//   return {
//     params,
//     data: response.data.invoices,
//     allData: response.data.allData,
//     totalPages: response.data.total
//   }
// })

export const getData = createAsyncThunk('appOrderTransactions/get', async (params) => {
    console.log(params)
      
    const response = await axios
    .get(`/api/orderTransactions/get`, {params})
    console.log(response)
    return {
      params,
      data: response,
      allData: response,
      totalPages: response.data.data.totalPages 
    }
})

// export const deletePromocode = createAsyncThunk('appOrderTransactions/delete', async (id, { dispatch, getState }) => {
//   console.log(id)
//   axios.delete(`/api/promocodes/delete`, {
//     params: { id }
//   })
//   .then(res => {
//     console.log(res)
//     if (res.data.success) {
//     //   console.log(getState())
//       dispatch(getData(getState().promocodes.params))
//       alert(`Order Transaction deleted successfully.`)
//     }
//   })
//   .catch(err => {
//     console.log(err)
//   })
 
//   return id
// })

export const appOrderTransactionsSlice = createSlice({
  name: 'appOrderTransactions',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
    //   console.log(action)
      state.data = action.payload.allData.data.data.alldata
      state.allData = action.payload.data.data.data.alldata
      state.total = action.payload.totalPages
      state.params = action.payload.params
    })
  }
})

export default appOrderTransactionsSlice.reducer
